
.pageFooter {
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    max-width: 400px;
    min-width: 200px;

    .link{
        text-decoration: none;
        color: rgba(201, 201, 201, 0.397);
        font-size: 25px;
    }
}