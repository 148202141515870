
.splashPage {
    background: url("../../assets/splashbackground.jpg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;

    .fadedCover{
        background-color: #12121281;
        height: 100%;
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        .title{
            color: white;
            text-shadow: 1px 1px 15px black;
            font-family: 'Lato', sans-serif;
            font-size: 72px;
            letter-spacing: 0.10em;
            text-transform: uppercase;
        }
    }
}