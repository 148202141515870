
.blogEntry {

    .blogHeader{
        display: flex;
        flex-direction: row;
        min-height: 400px;
        .backButton{
            margin:20px;
        }
        .titleContainer{
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title{
                font-size: 36px;
                text-align: center;
                text-transform: capitalize;
                padding-top: 20px;
            }
            .createdTime{
                font-size: 12px;
                text-align: center;
                .updatedTime{
                    font-size: 8px;
                    opacity: .6;
                }
            }
            .blogTags{
                display: flex;
                flex-direction: row;
                justify-content: center;
                .tag{
                    display: inline-block;
                    background: white;
                    color: black;
                    border-radius: 8px;
                    width: auto;
                    padding: 4px;
                    margin: 8px;
                }
            }
        }
    }


    .blogContent{
        margin: auto;
        max-width: 680px;
    }

    .endNavigation{
        display: flex;
        flex-direction: row;
        justify-content: center;

        .navLink{
            text-decoration: underline;
            margin: 12px;
        }
        .navLink:hover{
            cursor: pointer;
        }
    }
}