
.carosel {
    display: flex;
    flex-direction: row;
    width: 90vw;
    justify-content: space-between;
    overflow: hidden;
    margin: auto;
    .content{
        display: flex;
        flex-direction: row;
        width: 90%;
        flex: 0 0 auto;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 4px;
        scroll-behavior: smooth;
    }
    .content::-webkit-scrollbar {
        width: 8px; 
        height: 8px;
    }
    
    .content::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(255,255,255,255); 
        background-color:white;
    }

    .chevron{
        display:flex;
        justify-content:center;
        align-items:center;
        font-size: 40px;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
    }
}