
.aboutPage {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: auto;
    .aboutSection{
        display: flex;
        flex-direction: row;
        justify-content: center;
        .aboutLeft{
            max-width:600px;
            text-align: right;
            flex: 0 1 auto;
            padding:12px;
            font-size: 18px;
            letter-spacing: 0.12em;
        }
        .aboutRight{
            max-width:600px;
            text-align: left;
            flex: 0 1 auto;
            padding:12px;
            font-size: 18px;
            letter-spacing: 0.12em;
        }
        .aboutImage{
            width:100%;
        }
        .aboutSectionTitle{
            font-family: 'Lato';
            letter-spacing: 0.15em;
            text-transform: uppercase;
        }
    }
}