.navigationTabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.tab {
  font-family: "Lato";
  color: white !important;
  text-decoration: none !important;
  font-size: 24px;
  margin: 12px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  display: inline-block;
  padding: 15px 20px;
  position: relative;
}
.tab:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.tab-active:after {
  width: 100%;
  left: 0;
}
.tab-inactive:hover:after {
  width: 100%;
  left: 0;
}

.mobileTabs {
  display: none;
  flex-direction: column;
  max-width: 160px;
  .hamburgerIcon {
      padding: 12px;
  }
}

@media only screen and (max-width: 850px) {
  .navigationTabs {
    display: none;
  }
  .mobileTabs {
    display: flex;
  }
}
