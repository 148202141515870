
.albumCard {    
    position: relative;
    display: flex;
    flex: none;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 300px;
    width: 400px;
    margin: 12px;
    overflow: hidden;
    .fadedCover{
        background-color: #12121281;
        width: 100%;
        height: 100%;
        display:flex;
        flex-direction: column;
        justify-content:center;
        align-items:center;
        padding: 15px;
        .title{
            color: white;
            text-shadow: 1px 1px 15px black;
            font-family: 'Lato', sans-serif;
            font-size: 28px;
            letter-spacing: 0.10em;
            text-transform: uppercase;
            margin:auto;
            text-decoration: none;
            display: inline-block;
        }
        .title:hover{
            text-shadow: 0;
            font-size: 30px;
        }
        .title:hover:after{
            width: 100%; 
            left: 0; 
        }
        .title:after {    
            background: none repeat scroll 0 0 transparent;
            bottom: 0;
            content: "";
            display: block;
            height: 2px;
            left: 50%;
            position: absolute;
            background: #fff;
            transition: width 0.3s ease 0s, left 0.3s ease 0s;
            width: 0;
        }
        .data{
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            letter-spacing: 0.05em;
            .dataHeader{
                font-size: 12px;
                color: rgba(255, 255, 255, 0.637);
            }
            .dataContent{
                font-size: 16px;
            }
            padding-bottom: 20px;
        }
    }
}
