
.photosPage {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title{
        font-size: 45px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        text-align: center;
    }
    .desc{
        margin-top: 36px;
        text-align: center;
        width: 800px;
    }
    .content{
        margin: 24px;
        .sectionTitle{
            font-size: 35px;
            letter-spacing: 0.10em;
        }
        .albumCards{
            display: flex;
            flex-direction: row;
            overflow-x: auto;
        }
    }
}