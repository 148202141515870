
.pageNotFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;

    .notFoundIcon{
        font-size: 100px;
    }
}