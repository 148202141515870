
.favoritePhotos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    .photo{
        margin: 12px;
        text-decoration: none;
        transition: transform .2s;
    }
    .photo:hover{
        transform: scale(1.1);
    }
    .loadMore{
        background-color: black;
        margin: 12px;
        height:300px;
        width: 300px;
        text-decoration: none;
        font-size: 24px;
        transition: transform .2s;
        border-radius: 25px !important;
    }
    .loadMore:hover{
        transform: scale(1.1);
    }
}