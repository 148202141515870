
.pageComponent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .header{
        width:100%
    }
    .footer{
        display: flex;
        justify-content: center;
    }
}