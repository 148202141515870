html, 
body {
    height: 100%;
    margin: 0;
    background-color: #121212;
    color: white;
    font-family: 'Lato', sans-serif;;
}

.app{
    width:100%;
    height: 100vh;
}

.unbutton{
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.seperator{
    color:rgba(201, 201, 201, 0.397);
    background-color: rgba(201, 201, 201, 0.397);
    width: 60%;
    margin-top: 24px;
    margin-bottom: 24px;
}

a:link{
    color: white;
    text-decoration: underline;
}
a:visited{
    color: grey;
}
