
.contactPage {
    .title{
        text-align: center;
        font-size: 45px;
        letter-spacing: 0.10em;
    }
    .description{
        text-align: center;
        max-width: 700px;
        margin:auto;
    }

    .links{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 36px;
        
        .subTitle{
            text-align: center;
            font-size: 30px;
            letter-spacing: 0.10em;
        }

        .professionalSection{
            width: 300px;
            margin:24px;
            text-align: center;
        }
        .socialSection{
            width: 300px;
            margin:24px;
            text-align: center;
        }
        .socialLink{
            text-decoration: none;
            color: white;
            display: flex;
            margin: 12px;
            width: 200px;
            .linkIcon{
                font-size: 24px;
                width: 24px;
            }
            .linkName{
                display: flex;
                align-items: center;
                margin-left: 12px;
                vertical-align: middle;
            }
        }
    }

}